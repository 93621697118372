import { Component, OnInit, Inject , ViewEncapsulation, ViewChild, Output, EventEmitter } from '@angular/core';
import { company } from '../../../shared/dto/payrollSetup'; 
import { PayrollSetupService } from '../../../services/payroll-setup.service';
import { Observable } from 'rxjs';
import { CompanyService } from '../company.service'; 

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class CompanyComponent implements OnInit {

  companys : company[];
  errMess : string;
  currentCompany: number;

  constructor( private paySetupService: PayrollSetupService,
               private companyService : CompanyService) { }

  ngOnInit(): void {
    this.paySetupService.getCompanys().subscribe ( c =>  { 
      this.companys = c;
      if (this.companys.length > 0) {
          this.currentCompany = this.companys[0].companySerialNo;
          this.onChangeCompany( this.currentCompany );
        } 
    },  errmess => this.errMess = <any>errmess);

  }

  onChangeCompany($event)
  {
    console.log($event);
    this.currentCompany = $event;
    this.companyService.companyChanged($event);
  }

}
