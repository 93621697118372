import { Injectable } from '@angular/core';
import { Observable, Subject  } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private companySerial  = new Subject<number>();
  private currentCompanySerialNo : number = 0;

  compantChanged$ = this.companySerial.asObservable();
  constructor(  ) { }

  public get CompanySerialNo() {
    return this.currentCompanySerialNo;
  }

  companyChanged(company: number) {
   
    this.currentCompanySerialNo = company;
    this.companySerial.next(company);
    console.log(company);
  }

}
