import { Routes } from '@angular/router';
import { LayoutComponent } from '../layout/layout.component';
import { AuthGuard } from '../auth.guard';

export const routes: Routes = [

    {
        path: '',
        component: LayoutComponent,
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },       
            { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) }
            
        ]
    },

    // Not found
    { path: '**', redirectTo: 'home' }

];
