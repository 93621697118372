import { Injectable } from '@angular/core';
import {payCode, employee, occupationCode,company, jobRates, jobNumber} from '../shared/dto/payrollSetup';
import { Observable, of } from 'rxjs';
import { map , catchError} from 'rxjs/operators';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { baseURL } from '../shared/baseurl';
import { ProcessHTTPMsgService } from './process-httpmsg.service';

@Injectable({
  providedIn: 'root'
})
export class PayrollSetupService {

  constructor(private http: HttpClient,
              private processHTTPMsgService: ProcessHTTPMsgService) { }

  getPayCodes(): Observable<payCode[]> {
    return this.http.get<payCode[]>(baseURL + 'PayCodes/Get')
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getEmployees(): Observable<employee[]> {
    return this.http.get<employee[]>(baseURL + 'Employees/Get')
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getEmployee(id:number): Observable<employee[]> {
    return this.http.get<employee[]>(baseURL + 'Employees/Get?id=' + id)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getJobCodes(companyID:number): Observable<jobNumber[]> {
    return this.http.get<jobNumber[]>(baseURL + 'JobCodes/GetJobCodes/' + companyID)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getJobCode(id:string): Observable<jobNumber> {
    return this.http.get<jobNumber>(baseURL + 'JobCodes/GetJobCode/' + id)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  deleteJobCode(job : jobNumber): Observable<jobNumber> {
    
    return this.http.delete<jobNumber>(baseURL + 'JobCodes/deleteJobCode?id=' + job.id)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  postJobNumber(job : jobNumber): Observable<jobNumber> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    if (job.id == '00000000-0000-0000-0000-000000000000')
      return this.http.post<jobNumber>(baseURL + 'JobCodes/postJobCode', job, httpOptions)
        .pipe(catchError(this.processHTTPMsgService.handleError));
    else
      return this.http.put<jobNumber>(baseURL + 'JobCodes/putJobCode', job, httpOptions)
        .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getOccupationCodes(): Observable<occupationCode[]> {
    return this.http.get<occupationCode[]>(baseURL + 'OccupationCodes/Get')
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  deletePayRate(rate : jobRates): Observable<jobRates> {
    
    return this.http.delete<jobRates>(baseURL + 'OccupationCodes/deletePayRate?id=' + rate.id)
    .pipe(catchError(this.processHTTPMsgService.handleError));
  }


  getCompanys(): Observable<company[]> {
    return this.http.get<company[]>(baseURL + 'Companys/Get')
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  getPayRates(companyID:number): Observable<jobRates[]> {
    return this.http.get<jobRates[]>(baseURL + 'OccupationCodes/getPayRates/' + companyID)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }
  
  getPayRate(id:string): Observable<jobRates> {
    return this.http.get<jobRates>(baseURL + 'OccupationCodes/getPayRate/' + id)
      .pipe(catchError(this.processHTTPMsgService.handleError));
  }

  postPayRate(rate : jobRates): Observable<jobRates> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    if (rate.id == '00000000-0000-0000-0000-000000000000')
      return this.http.post<jobRates>(baseURL + 'OccupationCodes/postPayRate', rate, httpOptions)
        .pipe(catchError(this.processHTTPMsgService.handleError));
    else
      return this.http.put<jobRates>(baseURL + 'OccupationCodes/putPayRate', rate, httpOptions)
        .pipe(catchError(this.processHTTPMsgService.handleError));
  }
}
