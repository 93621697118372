
const Home = {
    text: 'Home',
    link: '/home',
    icon: 'icon-home'
};

const headingMain = {
    text: 'Main Navigation',
    heading: true
};

const timeWorked = {
    text: 'Time Worked Entry',
    link: 'timeworked',
    icon: 'icon-clock'
};

const timeWorkedSummary = {
    text: 'Time Worked Summary',
    link: 'timeworkedsummary',
    icon: 'icon-calculator'
};

const exportFile = {
    text: 'Export',
    link: 'ExportFile',
    icon: 'icon-cloud-download'
};

const exportHistory = {
    text: 'Export History',
    link: 'ExportHistory',
    icon: 'icon-calendar'
};

const jobRates = {
    text: 'Job Rates',
    link: 'JobRates',
    icon: 'calculator'
};

const jobNumbers = {
    text: 'Job Numbers',
    link: 'jobnumbers',
    icon: 'calculator'
};

export const menu = [
    headingMain,
    timeWorked,
    timeWorkedSummary,
    exportFile,
    exportHistory,
    jobNumbers,
    jobRates
];
